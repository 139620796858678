import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyCustomContent = lazy(
  () => import("./custom-content-Cl1irdYi.js").then((module) => ({
    default: module.CustomContent
  }))
);
function CustomContent({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyCustomContent, { ...props });
}
const useCustomContentContent = () => {
  return useMemo(
    () => ({
      customContent: {
        component: CustomContent
      }
    }),
    []
  );
};
export {
  LazyCustomContent as CustomContent,
  useCustomContentContent
};
